import { Drawer, message, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditableMaterialText from "../../../../common/components/EditableText/MaterialInput";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import ToastMessage from "../../../../common/components/Toast";
import { BASE_API } from "../../../../common/constants/constants";
import { getLocal } from "../../../../helpers/Local";
import { validateField } from "../../../../services/validations";
import EditableFormInput from "src/common/components/EditableText/EditableFormInput";
import moment from "moment";

const UnmatchedDicomSidebar = React.forwardRef(({ resource, isShow, handleCloseSidebar, timeDetail, timeRemaining }, ref) => {
  const sideNavStatus = useSelector((state) => state.common.sideNavStatus);
  const dispatch = useDispatch();
  const authId = getLocal("cvai-current-user");
  const [isToastr, setIsToastr] = useState(false);

  return (
    <Drawer
      title={timeDetail?.user?.name || " "}
      placement="right"
      closable
      className={sideNavStatus ? "wide" : ""}
      onClose={handleCloseSidebar}
      visible={isShow}
      destroyOnClose
      key="right"
    >
      <div ref={ref} className="resourceContainer new">
        {isToastr && <ToastMessage type="Schedules" status="success" msg="Saved Successfully!" />}

        <ScrollWrapper>
          <div
            className="resourceContent"
            style={{
              flex: "unset",
              paddingLeft: "24px",
              paddingRight: "20px",
              marginTop: "1.5rem",
            }}
          ></div>
        </ScrollWrapper>
      </div>
    </Drawer>
  );
});

export default UnmatchedDicomSidebar;
