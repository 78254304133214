import { createActionThunk } from "redux-thunk-actions";
import AT from "../actionTypes";

export const setProcedureReview = createActionThunk(AT.SET_PROCEDURE_MODULE_REVIEW, (type, data) => ({ [type]: data }));

export const change_medical_decision = createActionThunk(AT.CHANGE_MEDICAL_DECISION, (value) => value);

export const change_telehealth = createActionThunk(AT.CHANGE_TELEHEALTH, (value) => value);

export const changeTab = createActionThunk(AT.CHANGE_TAB, (data) => data);
