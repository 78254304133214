export default function ReplaceTrash(value, typeRemove, valueRemove = "") {
  if (value && value.length > 0) {
    const replacements = [
      // Array of replacements for better organization
      { search: /No No/g, replace: "No" },
      { search: /Not Not/g, replace: "Not" },
      { search: /undefined, /g, replace: " " },
    ];

    if (typeRemove) {
      replacements.push({ search: new RegExp(typeRemove, "g"), replace: valueRemove }); // Dynamic replacement
    }

    let newValue = value;
    for (const { search, replace } of replacements) {
      newValue = newValue.replace(search, replace);
    }
    return newValue;
  } else {
    return null;
  }
}
